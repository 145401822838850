import Auth from "@aws-amplify/auth";
import idx from "idx";
import { ApiResponse } from "clients/seller/model/ApiResponse";
import { CustomCognitoAttributes } from "config/LoginConfig";
import { logStart, logError, updateLogUser, log } from "common/utils/logging/Logger";
import { redirectToNextPage } from "common/utils/redirects/redirectToNextPage";
import {setAccountNotReady, setUserAlreadyAssociatedWithProvider} from "common/utils/storage/sessionStorage";
import {checkGrowSurfReferral, track} from "common/utils/tracking/Tracking";
import { loadOrganizationClient, loadUserClient } from "routes/chunks";
import { ShouldRenderApp } from "../ShouldRenderApp";
import { initialUserStage } from "./initialUserStage";
import {SellerServiceErrorCode} from "../errors/customErrors";

/**
 * Handles federated sign in and sign up:
 * - Creates an Organization and User in Seller Service for sign up
 * - Redirects to Seller Portal for both sign in and sign up
 */
export async function handleFederatedSignIn(user: any): Promise<ShouldRenderApp> {
  const { email } = user.attributes;

  updateLogUser({ email });
  logStart({ fn: "handleFederatedSignIn" });

  if (idx(user, (_) => _.attributes["custom:sellerIds"])) {
    return federatedUserSignIn();
  }

  return createFederatedUserSellerId(user, email);
}

function federatedUserSignIn(): ShouldRenderApp {
  return redirectToNextPage({ isSignUp: false });
}

async function createFederatedUserSellerId(user: any, email: string): Promise<ShouldRenderApp> {
  updateLogUser({ email });
  const ctx = logStart({ fn: "createFederatedUserSellerId" });

  if (process.env.REACT_APP_GROWSURF_CAMPAIGN_ID) {
    // must be email
    checkGrowSurfReferral(email);
  }

  const organizationClient = await loadOrganizationClient();
  let organization;
  let apiResponse: ApiResponse<any> | undefined;
  try {
    apiResponse = await organizationClient.createSeller(email, user.username);
  } catch (e) {
    logError(
      {
        ...ctx,
        e,
      },
      e.message
    );

    if (e.code === "SELLER_ALREADY_EXISTS") {
      await handleFederatedUserAlreadyExists();
      return false;
    }

    if (e.code === "ACCOUNT_NOT_READY") {
      setAccountNotReady('true');
      await Auth.signOut();
      return false;
    }

    return true;
  }

  try {
    organization = apiResponse!.data;
    log(ctx, `Created seller ${organization && organization.sellerId}`, { organization });

    // TODO: move this to a lambda
    await Auth.updateUserAttributes(user, {
      [CustomCognitoAttributes.OnboardingStage]: initialUserStage,
      name: email,
      email_verified: true,
    });

    track("Sign_Up_Account_Created");

    return redirectToNextPage({ isSignUp: true });
  } catch (e) {
    logError(
      {
        ...ctx,
        e,
      },
      e.message
    );
    return true;
  }
}

async function handleFederatedUserAlreadyExists() {
  const ctx = logStart({ fn: "handleFederatedUserAlreadyExists" });

  let provider = "unknown";
  try {
    const user = await Auth.currentAuthenticatedUser();
    const userClient = await loadUserClient();
    provider = await userClient.getExistingAuthenticationProvider(user.attributes.email);
  } catch (e) {
    logError(
      {
        ...ctx,
        e,
      },
      e.message
    );
  }

  setUserAlreadyAssociatedWithProvider(provider);
  await Auth.signOut();
}
