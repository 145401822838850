import { nanoid } from "nanoid";

// Currently the landing page for Seller Portal is the login page and it is an
// extra click to get to the sign up page. But we could imagine switching this
// in the future if we wanted there to be less friction to sign up.
//
// As well, for the partner auth flow we may want to be explicit about landing
// the user on either the login page or signup page depending on the partner's
// use case or preferences. For this reason, we have an explicit LOGIN route.

export enum LoginRoutes {
  LANDING = "/",
  LOGIN = "/login",
  SIGNUP = "/signup",
  LOGOUT = "/logout",
  FORGOT_PASSWORD = "/forgot-password",
  RESET_PASSWORD = "/reset-password",
  ADD_USER_TO_ORGANIZATION = "/add-user-to-organization",
  SMS_MFA = "/sms-mfa",
  CONSENT = "/consent",
}

export enum MarketingLinks {
  TOS = "https://www.flexport.com/terms-and-conditions",
  PP = "https://www.flexport.com/privacy",
}

export enum FederatedProviders {
  GOOGLE = "Auth0Google",
  FACEBOOK = "Auth0Facebook",
  SHOPIFY = "Auth0Shopify",
  FLEXPORT = "Auth0CoreFlexport",
}

export enum CustomCognitoAttributes {
  OnboardingStage = "custom:onboardingStage",
}

export const guestId = nanoid();
