import { checkRedirectTargetValidity } from "../../../config/redirectTarget";
import { clearSessionRedirectTarget, getSessionRedirectTarget } from "common/utils/storage/sessionStorage";

const SELLER_PORTAL_BASE_URL = process.env.REACT_APP_SELLER_PORTAL!;
const LOGIN_REDIRECT_PATH = "/login-redirect";
const ONBOARDING_PATH = "/onboarding/redirect";

export function redirectToSellerPortal(path: string = LOGIN_REDIRECT_PATH, type?: "login" | "signup") {
  const redirectTarget = getSessionRedirectTarget();
  const redirectPath = checkRedirectTargetValidity(redirectTarget, type) ? redirectTarget : path;
  clearSessionRedirectTarget();

  window.location.href = `${SELLER_PORTAL_BASE_URL}${redirectPath}`;
}

export function redirectToSellerPortalOnboarding() {
  redirectToSellerPortal(ONBOARDING_PATH, "signup");
}
