import Cookie from "js-cookie";
import { isDeviceKey, shouldForgetDevice } from "./rememberDevice";

const cognitoCookiePrefix = "CognitoIdentityServiceProvider";

const filterAuthEntries = (key: string) =>
  key.match(cognitoCookiePrefix) && (!isDeviceKey(key) || shouldForgetDevice());

export function clearSellerPortalAuth() {
  Object.keys(Cookie.getJSON())
    .filter(filterAuthEntries)
    .forEach((key) => Cookie.remove(key, { path: "/", domain: process.env.REACT_APP_COOKIE_DOMAIN_SELLER_PORTAL }));

  Object.keys(window.localStorage)
    .filter(filterAuthEntries)
    .forEach((key) => window.localStorage.removeItem(key));
}
