import { logError, log } from "../logging/Logger";

const googleTagManagerDataLayer = (window as any).dataLayer;

function getGrowSurf(): Promise<any> {
  const globalScope = window as any;

  return new Promise((resolve) => {
    // do not block signup in case growsurf is down
    const expire = setTimeout(() => {
      resolve(undefined);
    }, 5000);

    // https://docs.growsurf.com/integrate/javascript-web-api#example-of-using-grsfready
    if (!globalScope.growsurf) {
      // Listen and wait for the Growsurf Universal Code to initialize
      globalScope.addEventListener("grsfReady", () => {
        clearTimeout(expire);
        resolve(globalScope.growsurf);
      });
    } else {
      clearTimeout(expire);
      resolve(globalScope.growsurf);
    }
  });
}

export function sendGTMData(payload: object) {
  if (googleTagManagerDataLayer) {
    try {
      googleTagManagerDataLayer.push(payload);
    } catch (error) {
      logError({ fn: "sendGTMData", error }, error.message);
    }
  }
}

export async function checkGrowSurfReferral(email: string) {
  // loaded by Google tag manager
  // See: https://tagmanager.google.com/#/container/accounts/3973004448/containers/9795225/workspaces/60/tags/64
  const growsurf = await getGrowSurf();
  if (growsurf) {
    const referrerId: string | null = await growsurf.getReferrerId();
    return growsurf.addParticipant(email, referrerId);
  }
}

export const track = (event: string): void => {
  if (!process.env.REACT_APP_TRACKING) {
    return;
  }
  // FB Pixel Tracking
  try {
    const windowFb = (window as any).fbq;
    if (windowFb) {
      windowFb("trackCustom", event);
      log({ fn: "track" }, "Successfully tracked FB event");
    } else {
      logError({ fn: "track" }, new Error("window.fbq is not found"));
    }
  } catch (error) {
    logError(
      {
        fn: "track",
        tracker: "FBQ",
        error,
      },
      error.message
    );
  }
  // GA Event Tracking
  try {
    // GA/GTM Event Tracking
    sendGTMData({
      event: event,
      category: event,
    });
  } catch (error) {
    logError(
      {
        fn: "track",
        tracker: "GA",
        error,
      },
      error.message
    );
  }
};
