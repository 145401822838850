export async function loadOrganizationClient() {
  const { OrganizationClient } = await import(/* webpackChunkName: "OrganizationClient" */ "clients/seller/OrganizationClient");

  return new OrganizationClient({
    baseURL: process.env.REACT_APP_ORGANIZATION_SERVICE!
  });
}

export async function loadUserClient() {
  const { UserClient } = await import(/* webpackChunkName: "UserClient" */ "clients/seller/UserClient");

  return new UserClient({
    baseURL: process.env.REACT_APP_USER_SERVICE!
  });
}

export async function loadLogisticsApiClient() {
  const { LogisticsApiClient } = await import(/* webpackChunkName: "UserClient" */ "clients/LogisticsApiClient");

  return new LogisticsApiClient({
    baseURL: process.env.REACT_APP_LOGISTICS_API_URL!
  });
}

export function loadApp() {
  return import(/* webpackChunkName: "App" */ "App");
}

export function loadLogin() {
  return import(/* webpackChunkName: "LoginPage" */ "routes/login/LoginPage");
}

export function loadLogout() {
  return import(/* webpackChunkName: "LogoutPage" */ "routes/logout/LogoutPage");
}

export function loadSignUp() {
  return import(/* webpackChunkName: "SignupPage" */ "routes/signup/SignUpPage");
}

export function loadForgotPassword() {
  return import(/* webpackChunkName: "ForgotPasswordPage" */ "routes/forgot-password/ForgotPasswordPage");
}

export function loadResetPassword() {
  return import(/* webpackChunkName: "ResetPasswordPage" */ "routes/reset-password/ResetPasswordPage");
}

export function loadAddUserToOrganization() {
  return import(/* webpackChunkName: "AddUserToOrganizationPage" */ "routes/add-user-to-organization/AddUserToOrganizationPage");
}

export function loadSmsMfa() {
  return import(/* webpackChunkName: "SmsMfaPage" */ "routes/sms-mfa/SmsMfaPage");
}

export function loadConsentPage() {
  return import(/* webpackChunkName: "ConsentPage" */ "routes/consent-page/ConsentPage");
}