import { setSessionRedirectTarget } from "common/utils/storage/sessionStorage";
import { RedirectPaths } from "./redirectPaths";

// List of valid paths that are allowed for redirection if they exist in the URL.
// Add valid redirect paths to this array.
const VALID_LOGIN_REDIRECT_TARGETS: RedirectPaths[] = [RedirectPaths.SELLER_PORTAL, RedirectPaths.PARCEL];

// If a signup is coming from Parcel, we want to redirect back to Parcel.
// The rest of the time we want to make sure to redirect to /onboarding/redirect
// even if a redirectUrl was provided for some reason.
const VALID_SIGNUP_REDIRECT_TARGETS: RedirectPaths[] = [RedirectPaths.PARCEL];

export const checkRedirectTargetValidity = (
  redirectTarget: string | null | undefined,
  type?: "login" | "signup"
): redirectTarget is string => {
  if (!redirectTarget) {
    return false;
  }

  const validRedirectTargets = type === "signup" ? VALID_SIGNUP_REDIRECT_TARGETS : VALID_LOGIN_REDIRECT_TARGETS;

  return validRedirectTargets.some((target) => redirectTarget.startsWith(target));
};

export const storeRedirectTargetInSession = (redirectTarget: string | null | undefined) => {
  if (!checkRedirectTargetValidity(redirectTarget)) {
    return;
  }
  setSessionRedirectTarget(redirectTarget);
};
