import React from "react";
import "./index.scss";

import ReactDOM from "react-dom";
import { startAmplifyHub } from "actions/HubActions";
import { loadApp } from "routes/chunks";
import { logError, initializeLogger } from "common/utils/logging/Logger";

const DOM_ROOT = document.getElementById("root");

export async function renderApp(callback?: () => void) {
  const { App } = await loadApp();

  return ReactDOM.render(<App />, DOM_ROOT, callback);
}

initializeLogger();
startAmplifyHub()
  .then((shouldRender) => {
    shouldRender && renderApp();
  })
  .catch((err) => logError({ fn: "startAmplifyHub", err }, err.message));
