import { ShouldRenderApp } from "../ShouldRenderApp";
import { getLogisticsApiPartnerAuthState } from "../storage/sessionStorage";
import { redirectToConsentPage } from "./redirectToConsentPage";
import { redirectToSellerPortalOnboarding, redirectToSellerPortal } from "./redirectToSellerPortal";

/**
 * Redirects the user to the next page after authentication, which could be:
 * - Seller Portal onboarding for a new sign up
 * - The normal Seller Portal login redirect for a sign in
 * - The consent page for a sign up or sign in during the Logistics API partner auth flow
 */
export function redirectToNextPage({ isSignUp }: { isSignUp: boolean }): ShouldRenderApp {
  if (getLogisticsApiPartnerAuthState()) {
    redirectToConsentPage();
    return true;
  } else if (isSignUp) {
    redirectToSellerPortalOnboarding();
    return false;
  } else {
    redirectToSellerPortal();
    return false;
  }
}
