import AuthStorageHost from "common/utils/storage/AuthStorageHost";

export const amplifyConfig = {
  Auth: {
    identityPoolId: process.env.REACT_APP_COGNITO_IDENTITY_POOL,
    region: process.env.REACT_APP_COGNITO_REGION,
    userPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_COGNITO_USER_POLL_WEBCLIENT_ID,
    storage: new AuthStorageHost(process.env.REACT_APP_COOKIE_DOMAIN_SELLER_PORTAL!),
    oauth: {
      domain: process.env.REACT_APP_COGNITO_USER_POOL_DOMAIN,
      scope: ["email", "profile", "openid", "aws.cognito.signin.user.admin"],
      redirectSignIn: process.env.REACT_APP_OAUTH_CALLBACK_URL,
      redirectSignOut: process.env.REACT_APP_OAUTH_SIGNOUT_URL,
      responseType: "code",
    },
  },
};
