import { some } from "lodash/fp";

// These keys are used for remembering the device for MFA, so they should not be cleared if we want to remember the device.
const DEVICE_KEYS = ["deviceKey", "randomPasswordKey", "deviceGroupKey"];

export const isDeviceKey = (key: string) => some((deviceKey) => key.includes(deviceKey), DEVICE_KEYS);

// Cognito remembers the device indefinitely, but we want to remember the device for a limited time.
const DEVICE_REMEMBERED_AT_KEY = "deviceRememberedAt";
const DEVICE_REMEMBERED_AT_DURATION_DAYS = 30;
const DEVICE_REMEMBERED_AT_DURATION_MS = DEVICE_REMEMBERED_AT_DURATION_DAYS * 24 * 60 * 60 * 1000;

export const setDeviceRememberedAt = () => {
  localStorage.setItem(DEVICE_REMEMBERED_AT_KEY, new Date().toISOString());
};

export const shouldForgetDevice = () => {
  try {
    const deviceRememberedAt = localStorage.getItem(DEVICE_REMEMBERED_AT_KEY);
    return (
      !deviceRememberedAt ||
      new Date().getTime() - new Date(deviceRememberedAt).getTime() > DEVICE_REMEMBERED_AT_DURATION_MS
    );
  } catch {
    return true;
  }
};

// Seller Portal needs to have the remembered device key, if there is one, in order
// to perform actions that refresh the session. The method we transmit authentication
// details to Seller Portal is via cookies. When we confirm sign in for MFA with a code,
// this sets the cookies for the device key. However, when we don't have this extra step,
// such as on any normal sign in without MFA or when using MFA but we remember the device,
// we need to handle setting the cookies.
export const ensureDeviceKeyCookiesExist = (user: any) => {
  // Populate user device key properties from cached values in local storage
  user.getCachedDeviceKeyAndPassword();

  // If no remembered device key, do not update cache, as this would set cookies and
  // local storage with undefined values
  if (!user.deviceKey) {
    return;
  }

  // Cache device key properties to cookies (and local storage)
  user.cacheDeviceKeyAndPassword();
};
