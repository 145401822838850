import { datadogRum } from "@datadog/browser-rum";
import { datadogLogs } from "@datadog/browser-logs";
import { InitConfiguration } from "@datadog/browser-core";
import {
  compositeStream,
  conditionalStream,
  consoleStream,
  datadogErrorTrackingStream,
  datadogLoggerStream,
  logger,
  minimumLevelStream,
  setGlobalContext,
  setStream,
} from "@deliverr/ui-logging";
import { guestId } from "config/LoginConfig";
import { LogContext } from "./LogContext";
import { appVersion } from "AppData";

const globalScope = window as any;

type LogEnvironment = "development" | "staging" | "production";

export function initializeLogger() {
  const logEnv = (process.env.REACT_APP_LOG_ENV as LogEnvironment) || "development";
  const isValidDatadogEnv = logEnv !== "development";

  if (process.env.REACT_APP_TRACKING && globalScope.FS && globalScope.FS.identify) {
    // associates guestId within a future user session
    // See: https://help.fullstory.com/hc/en-us/articles/360020828113-FS-identify-Identifying-users
    globalScope.FS.setUserVars({ guestId });
  }

  setStream(
    compositeStream(
      conditionalStream(() => !isValidDatadogEnv)(minimumLevelStream("info")(consoleStream)),
      conditionalStream(() => isValidDatadogEnv)(
        compositeStream(datadogLoggerStream(datadogLogs.logger), datadogErrorTrackingStream(datadogRum))
      )
    )
  );

  if (!isValidDatadogEnv) {
    return;
  }

  const baseConfig: Pick<InitConfiguration, "clientToken" | "site" | "service" | "env" | "version"> = {
    clientToken: "pub7dba0a73d58b4cf871d9492e69e34e56",
    site: "datadoghq.com",
    service: "seller-portal-login",
    env: logEnv,
    version: appVersion,
  };

  datadogLogs.init({
    ...baseConfig,
    forwardErrorsToLogs: true,
    sampleRate: 100,
  });

  datadogRum.init({
    ...baseConfig,
    applicationId: "f5245f8c-a248-47ea-b419-c0a6ef708fe9",
    sessionSampleRate: 100,
    sessionReplaySampleRate: 0,
    trackUserInteractions: false,
    defaultPrivacyLevel: "mask-user-input",
  });
}

export const updateLogUser = (user: { email: string; sellerId?: string }) => {
  setGlobalContext({
    user,
  });

  datadogRum.setUser({
    id: user.sellerId,
    email: user.email,
    sellerId: user.sellerId,
  });

  // Full Story
  if (globalScope.FS && globalScope.FS.identify) {
    globalScope.FS.identify(user.email, user);
  }
};

export const logStart = (ctx: LogContext): LogContext => {
  logger.info(ctx, `starting ${ctx.fn}`);
  return ctx;
};

export const logError = (ctx: LogContext, err: Error) => {
  logger.error({ ...ctx, err }, `error during ${ctx.fn}`);
};

export const log = (ctx: LogContext, msg: string, payload?: object) => {
  logger.info({ ...ctx, ...payload }, msg);
};
