import Auth from "@aws-amplify/auth";
import { Seller } from "clients/seller/model/Seller";
import { logStart, logError, updateLogUser, log } from "common/utils/logging/Logger";
import { ensureDeviceKeyCookiesExist } from "common/utils/storage/rememberDevice";
import { redirectToNextPage } from "common/utils/redirects/redirectToNextPage";
import { ShouldRenderApp } from "common/utils/ShouldRenderApp";
import { checkGrowSurfReferral, track } from "common/utils/tracking/Tracking";
import { CustomCognitoAttributes } from "config/LoginConfig";
import { loadOrganizationClient } from "routes/chunks";
import { initialUserStage } from "./initialUserStage";

export async function handleEmailSignIn(user: any, password: string): Promise<ShouldRenderApp> {
  if (user) {
    ensureDeviceKeyCookiesExist(user);

    const attributes = user.attributes;

    updateLogUser({ email: attributes.email });
    logStart({ fn: "onSignIn", user: attributes });

    if (!attributes["custom:sellerIds"]) {
      await createSellerId(attributes, password);
      track("Sign_Up_Account_Created");
      return redirectToNextPage({ isSignUp: true });
    }
  }

  return redirectToNextPage({ isSignUp: false });
}

export async function handleEmailSignUp(user: any, password: string) {
  updateLogUser({ email: user.username });
  logStart({ fn: "onSignUp" });

  await createSellerId(
    {
      ...user,
      email: user.email,
    },
    password
  );

  track("Sign_Up_Account_Created");
  track("Created Account");

  redirectToNextPage({ isSignUp: true });
}

async function createSellerId(user: any, password: string): Promise<Seller | undefined> {
  const ctx = logStart({ fn: "createSellerId" });

  // email for signup or cognito id for facebook/google signup
  const userKey = user && (user.username || user.email || user.name);

  updateLogUser({ email: userKey });

  if (process.env.REACT_APP_GROWSURF_CAMPAIGN_ID) {
    // must be email - userKey is email for signups
    // social signups get added to growsurf in createFederatedUserSellerID
    checkGrowSurfReferral(userKey);
  }

  try {
    const organizationClient = await loadOrganizationClient();
    let organization;
    const apiResponse = await organizationClient.createSeller(userKey, userKey);
    organization = apiResponse!.data;
    log(ctx, `Created seller ${organization && organization.sellerId}`, { organization });

    if (organization) {
      updateLogUser({ email: userKey, sellerId: organization.sellerId });
    }

    // Do a second login here so that we have the `custom:sellerIds` user attribute value
    // that was set by the call to organizationClient.createSeller. Seller Portal will not
    // work without this.
    await Auth.signIn(userKey, password);

    const updatedUser = await Auth.currentAuthenticatedUser();

    await Auth.updateUserAttributes(updatedUser, {
      [CustomCognitoAttributes.OnboardingStage]: initialUserStage,
    });

    return organization;
  } catch (e) {
    logError(
      {
        ...ctx,
        e,
      },
      e.message
    );
    if (e.code === 'EMAIL_SIGNUP_NOT_ALLOWED' || e.code === 'ACCOUNT_NOT_READY') {
      throw e;
    }
    return;
  }
}
