import { CookieStorage } from "@aws-amplify/auth";
import { ICognitoStorage } from "amazon-cognito-identity-js";

export default class AuthStorageHost implements ICognitoStorage {
  cookieStorage: CookieStorage;
  defaultStorage: Storage;

  constructor(domain: string) {
    this.cookieStorage = new CookieStorage({
      domain,
      path: "/",
      expires: 30,

      // cross-domain auth doesn't work with option secure = true :(
      secure: false,
    });
    this.defaultStorage = window.localStorage;
  }

  setItem(key: string, value: string) {
    // transfer data to seller portal
    this.cookieStorage.setItem(key, value);
    this.defaultStorage.setItem(key, value);
  }

  getItem(key: string) {
    return this.defaultStorage.getItem(key)!;
  }

  removeItem(key: string) {
    this.defaultStorage.removeItem(key);
  }

  clear() {
    this.defaultStorage.clear();
  }
}
