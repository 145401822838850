import {
  loadLogin,
  loadSignUp,
  loadForgotPassword,
  loadResetPassword,
  loadLogout,
  loadAddUserToOrganization,
  loadSmsMfa,
  loadConsentPage
} from "routes/chunks";
import Loadable from "react-loadable";
import Loading from "components/Loading";

const createLoadable = (loader: () => Promise<any>) =>
  Loadable({
    loader,
    loading: Loading,
    delay: 400, // don't show loading if page takes less than 400ms to load, avoid flash loading
  });

// loaders
export const LoginPageLoader = createLoadable(loadLogin);
export const LogoutPageLoader = createLoadable(loadLogout);
export const SignUpPageLoader = createLoadable(loadSignUp);
export const ForgotPasswordPageLoader = createLoadable(loadForgotPassword);
export const ResetPasswordPageLoader = createLoadable(loadResetPassword);
export const AddUserToOrganizationPageLoader = createLoadable(loadAddUserToOrganization);
export const SmsMfaPageLoader = createLoadable(loadSmsMfa);
export const ConsentPageLoader = createLoadable(loadConsentPage);

// preloaders
export const LoginPagePreloader = () => LoginPageLoader.preload();
export const SignupPagePreloader = () => SignUpPageLoader.preload();
export const ForgotPasswordPagePreloader = () => ForgotPasswordPageLoader.preload();
