import Amplify, { Auth, Hub } from "aws-amplify";
import { ConsoleLogger } from "@aws-amplify/core";
import { HubPayload } from "@aws-amplify/core/lib/Hub";
import idx from "idx";
import qs from "qs";
import { handleEmailSignIn } from "common/utils/account/handleEmailSignIn";
import { handleFederatedSignIn } from "common/utils/account/handleFederatedSignIn";
import { logStart, logError } from "common/utils/logging/Logger";
import { ShouldRenderApp } from "common/utils/ShouldRenderApp";
import { clearSellerPortalAuth } from "common/utils/storage/clearSellerPortalAuth";
import { ensureDeviceKeyCookiesExist } from "common/utils/storage/rememberDevice";
import { setCustomStateFailure, setLogisticsApiPartnerAuthState, setPartnerName } from "common/utils/storage/sessionStorage";
import { amplifyConfig } from "config/amplifyConfig";
import { storeRedirectTargetInSession } from "config/redirectTarget";
import { ConsentPageLoader } from "routes";
import { loadLogisticsApiClient } from "routes/chunks";

const logger = new ConsoleLogger("HubActions");

async function userIsAuthenticated(): Promise<ShouldRenderApp> {
  const user = await Auth.currentAuthenticatedUser();

  ensureDeviceKeyCookiesExist(user);

  if (user && user.username.match("Auth0")) {
    return handleFederatedSignIn(user);
  }

  return handleEmailSignIn(user, "");
}

export function hasGoogleOrFacebookOrShopifyAuthError(): boolean {
  return !window.location.href.includes("?code=");
}

export async function startAmplifyHub(): Promise<ShouldRenderApp> {
  const params = qs.parse(window.location.search.slice(1));

  storeRedirectTargetInSession(params.redirectUrl);

  if (params.flexportState) {
    setLogisticsApiPartnerAuthState(params.flexportState);
    const logisticsApiClient = await loadLogisticsApiClient();
    const partnerName = await logisticsApiClient.getPartnerName(params.flexportState);
    setPartnerName(partnerName);
    ConsentPageLoader.preload();
  }

  Amplify.configure(amplifyConfig);

  const credentials = await Auth.currentUserCredentials();

  if (idx(credentials, (_) => _.authenticated) && !window.location.pathname.match("logout")) {
    // refresh token
    await Auth.currentSession();

    return userIsAuthenticated();
  }

  Hub.listen("auth", (capsule) => {
    logger.info(capsule.payload.event);

    switch (capsule.payload.event) {
      case "signOut":
        onSignOut(capsule.payload);
        break;
      case "customState_failure":
        onCustomStateFailure(capsule.payload);
        break;
    }
  });

  return hasGoogleOrFacebookOrShopifyAuthError();
}

function onSignOut(payload: HubPayload) {
  logStart({ fn: "onSignOut", payload });

  clearSellerPortalAuth();
}

function onCustomStateFailure(payload: HubPayload) {
  logError({ fn: "customState_failure" }, payload.event as any);
  setCustomStateFailure("true");
}
